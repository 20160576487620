$Navy: #0a192f;
$LightNavy: #112240;
$LightestNavy: #303c55;
$Slate: #8892b0;
$LightSlate: #a8b2d1;
$LightestSlate: #ccd6f6;
$White: #e6f1ff;
$Green: #64ffda;
$GreenOpacity: rgba(100, 255, 218, 0.07);

$MainFont: Calibre, San Francisco, SF Pro Text, -apple-system, system-ui,
  BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;
$CodeFont: SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco,
  Monospace;
